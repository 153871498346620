var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var SystemLogService = /** @class */ (function (_super) {
    __extends(SystemLogService, _super);
    function SystemLogService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 系统登录日志查询
     */
    SystemLogService.prototype.findAllLoginLog = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.systemLogController.findAllLoginLog,
            data: {
                realName: data.realName,
                username: data.username,
                ip: data.ip,
                type: data.type,
                isSuccess: data.isSuccess,
                minLoginTime: data.loginTime[0],
                maxLoginTime: data.loginTime[1]
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 批量删除登录日志
     */
    SystemLogService.prototype.batchDeleteLog = function (ids, loading) {
        return this.netService.send({
            server: manageService.systemLogController.batchDeleteLog,
            data: {
                ids: ids
            },
            loading: loading
        });
    };
    /**
     * 系统日志查询
     */
    SystemLogService.prototype.findAllSystemLog = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.systemLogController.findAllSystemLog,
            data: {
                operator: data.operator,
                remark: data.remark,
                minOperateTime: data.operatorTime[0],
                maxOperateTime: data.operatorTime[1]
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 批量删除系统日志
     */
    SystemLogService.prototype.batchDeleteSystemLog = function (ids, loading) {
        return this.netService.send({
            server: manageService.systemLogController.batchDeleteSystemLog,
            data: {
                ids: ids
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], SystemLogService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], SystemLogService.prototype, "findAllLoginLog", null);
    __decorate([
        Debounce()
    ], SystemLogService.prototype, "batchDeleteLog", null);
    __decorate([
        Debounce()
    ], SystemLogService.prototype, "findAllSystemLog", null);
    __decorate([
        Debounce()
    ], SystemLogService.prototype, "batchDeleteSystemLog", null);
    return SystemLogService;
}(Service));
export { SystemLogService };
